import axios from 'axios';

interface IProps {
  body: any;
  uri?: string;
}

export const fetchPOST = async ({ body, uri }: IProps): Promise<boolean> => {
  const location = window.location.origin;

  let BASE_URI;

  if (location === 'http://localhost:3000') {
    BASE_URI = uri ? `http://react/Api/${uri}/` : 'http://react/Api/';
  } else {
    BASE_URI = uri ? `${location}/Api/${uri}/` : `${location}/Api/`;
  }

  const { data } = await axios.post(BASE_URI, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: body,
  });

  return data.status === 'success';
};
