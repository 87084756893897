import { useTranslation } from 'react-i18next';
import './Areas.scss';

import { AreaItems } from './AreaItems';
import amaks from '../../../assets/images/Area/amaks.jpg';
import art from '../../../assets/images/Area/art.jpg';
import azimut from '../../../assets/images/Area/azimut.jpg';
import benefit from '../../../assets/images/Area/benefit.jpg';
import degas from '../../../assets/images/Area/degas.jpg';
import expo from '../../../assets/images/Area/expo.jpg';
import express from '../../../assets/images/Area/express.jpg';
import hampton from '../../../assets/images/Area/hampton.jpg';
import inc from '../../../assets/images/Area/inc.jpg';
import les from '../../../assets/images/Area/les.jpg';
import marriot from '../../../assets/images/Area/marriot.jpg';
import mercure from '../../../assets/images/Area/mercure.jpg';
import passag from '../../../assets/images/Area/passag.jpg';
import ramada from '../../../assets/images/Area/ramada.jpg';
import scor from '../../../assets/images/Area/scor.jpg';
import taiga from '../../../assets/images/Area/taiga.jpg';
import texs from '../../../assets/images/Area/texs.jpg';
import yar from '../../../assets/images/Area/yar.jpg';

export const Areas = (): JSX.Element => {
  const { t } = useTranslation();

  const area = [
    { id: 1, title: t('Location.title.marriot'), images: marriot },
    { id: 2, title: t('Location.title.ramada'), images: ramada },
    { id: 3, title: t('Location.title.art'), images: art },
    { id: 4, title: t('Location.title.degas'), images: degas },
    { id: 5, title: t('Location.title.benefit'), images: benefit },
    { id: 6, title: t('Location.title.mercure'), images: mercure },
    { id: 7, title: t('Location.title.yar'), images: yar },
    { id: 8, title: t('Location.title.passag'), images: passag },
    { id: 9, title: t('Location.title.express'), images: express },
    { id: 10, title: t('Location.title.amaks'), images: amaks },
    { id: 11, title: t('Location.title.azimut'), images: azimut },
    { id: 12, title: t('Location.title.hampton'), images: hampton },
    { id: 13, title: t('Location.title.expo'), images: expo },
    { id: 14, title: t('Location.title.texs'), images: texs },
    { id: 15, title: t('Location.title.inc'), images: inc },
    { id: 16, title: t('Location.title.taiga'), images: taiga },
    { id: 17, title: t('Location.title.scor'), images: scor },
    { id: 18, title: t('Location.title.les'), images: les },
  ];

  return (
    <div className="area">
      <div className="container pt-5 pb-5 d-flex flex-wrap align-content-center justify-content-between area-wrapper">
        {area.map(({ id, title, images }) => (
          <AreaItems key={id} id={id} title={title} images={images} />
        ))}
      </div>
    </div>
  );
};
