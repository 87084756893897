import cn from 'classname';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Close } from '../../../assets/images/Header/close.svg';
import { fetchPOST } from '../../../shared/utils/fetchPOST';
import { SuccessForm } from '../../SuccessForm';
interface IProps {
  callback?: (status: boolean) => void;
}

export const AboutForm = ({ callback }: IProps): JSX.Element => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const defaultValue = [
    { name: 'name', value: '', require: false },
    { name: 'email', value: '', require: true },
    { name: 'phone', value: '', require: true },
    { name: 'message', value: '', require: false },
    { name: 'robot', value: 'false', require: true },
    { name: 'save', value: 'true', require: true },
  ];

  const getDefaultValue = (name): string => {
    const find = form.find((item) => item.name === name);
    if (find.value !== '') {
      return find.value;
    } else {
      return '';
    }
  };

  const getDefaultChecked = (name): boolean => {
    const find = form.find((item) => item.name === name);
    if (find.value !== 'false') {
      return Boolean(find.value);
    } else {
      return false;
    }
  };

  const [form, setForm] = useState(defaultValue);

  const handlerForm = async () => {
    const error = form
      .map(({ name, value, require }) => {
        if (require) {
          if (value === '' || value === 'false') {
            return { name: name, value: 'error' };
          }
        }
      })
      .filter(Boolean);

    if (error.length === 0) {
      const data = {};
      form.forEach(({ name, value }) => (data[name] = value));

      const result = await fetchPOST({ body: data });

      if (result) {
        setSuccess(true);

        setTimeout(() => {
          setSuccess(false);
          setForm(defaultValue);
        }, 2000);
      }
    } else {
      setError(t('About.form.error'));
    }
  };

  const handlerFormInput = (e, name) => {
    const { value } = e.target;
    const input = form.find((item) => item.name === name);
    const filter = form.filter((item) => item.name !== name);
    input.value = value;
    setForm([...filter, input]);
    setError(null);
  };

  const handlerFormCheckbox = (e, name) => {
    const { checked } = e.target;
    const input = form.find((item) => item.name === name);
    const filter = form.filter((item) => item.name !== name);
    input.value = checked.toString();
    setForm([...filter, input]);
    setError(null);
  };

  return (
    <>
      <div className={cn(callback ? 'container pt-4' : 'container pt-5 pb-2')}>
        {callback ? (
          <>
            <p className="about-title call text-center pb-2">
              {t('About.form.title')}
            </p>
            <div className="close-callback" onClick={() => callback(false)}>
              <Close width="50px" />
            </div>
          </>
        ) : (
          <p className="about-title text-center pb-2">
            {t('About.form.title')}
          </p>
        )}

        <div className="d-flex align-content-center justify-content-center gap-5 about-input-wrapper">
          <div className="d-grid gap-3">
            <input
              className="about-input"
              type="text"
              placeholder={t('About.form.name')}
              onChange={(e) => handlerFormInput(e, 'name')}
              value={getDefaultValue('name')}
            />
            <input
              className="about-input"
              type="text"
              placeholder={t('About.form.email')}
              onChange={(e) => handlerFormInput(e, 'email')}
              value={getDefaultValue('email')}
            />
            <input
              className="about-input"
              type="text"
              placeholder={t('About.form.phone')}
              onChange={(e) => handlerFormInput(e, 'phone')}
              value={getDefaultValue('phone')}
            />
          </div>
          <textarea
            className="about-textarea"
            placeholder={t('About.form.message')}
            onChange={(e) => handlerFormInput(e, 'message')}
            value={getDefaultValue('message')}
          />
        </div>
        <div className="d-flex align-content-center justify-content-center gap-5 pt-3 about-input-wrapper">
          <p className="about-checkboxs mb-0">{t('About.form.required')}</p>
          <div className="about-checkboxs">
            <label className="d-flex gap-2">
              <input
                className="about-checkbox"
                type="checkbox"
                onChange={(e) => handlerFormCheckbox(e, 'robot')}
                checked={getDefaultChecked('robot')}
              />
              {t('About.form.robot')}
            </label>
            <label className="d-flex gap-2">
              <input
                className="about-checkbox"
                type="checkbox"
                defaultChecked={true}
                onChange={(e) => handlerFormCheckbox(e, 'save')}
                checked={getDefaultChecked('save')}
              />
              {t('About.form.yes')}
            </label>
          </div>
        </div>
      </div>
      {error && (
        <div className="alert-about alert alert-danger text-center">
          {error}
        </div>
      )}
      {success && (
        <SuccessForm
          text={t('About.form.success')}
          subtext={t('About.form.success-bottom')}
        />
      )}
      <div className="button-wrapper">
        <div className="button-about" onClick={handlerForm}>
          {t('About.form.send')}
        </div>
      </div>
    </>
  );
};
