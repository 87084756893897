import cn from 'classname';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './Contacts.scss';
import { Info } from './Info';
import contact from '../../../assets/images/Background/contact.png';
import { fetchPOST } from '../../../shared/utils/fetchPOST';
import { SuccessForm } from '../../SuccessForm';

export const Contacts = (): JSX.Element => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const defaultValue = [
    { name: 'name', value: '', require: false },
    { name: 'email', value: '', require: true },
    { name: 'message', value: '', require: false },
    { name: 'robot', value: 'false', require: true },
  ];

  const getDefaultValue = (name): string => {
    const find = form.find((item) => item.name === name);
    if (find.value !== '') {
      return find.value;
    } else {
      return '';
    }
  };

  const getDefaultChecked = (name): boolean => {
    const find = form.find((item) => item.name === name);
    if (find.value !== 'false') {
      return Boolean(find.value);
    } else {
      return false;
    }
  };

  const [form, setForm] = useState(defaultValue);

  const handlerForm = async () => {
    const error = form
      .map(({ name, value, require }) => {
        if (require) {
          if (value === '' || value === 'false') {
            return { name: name, value: 'error' };
          }
        }
      })
      .filter(Boolean);

    if (error.length === 0) {
      const data = {};
      form.forEach(({ name, value }) => (data[name] = value));

      const result = await fetchPOST({ body: data });

      if (result) {
        setSuccess(true);

        setTimeout(() => {
          setSuccess(false);
          setForm(defaultValue);
        }, 2000);
      }
    } else {
      setError(t('About.form.error'));
    }
  };

  const handlerFormInput = (e, name) => {
    const { value } = e.target;
    const input = form.find((item) => item.name === name);
    const filter = form.filter((item) => item.name !== name);
    input.value = value;
    setForm([...filter, input]);
    setError(null);
  };

  const handlerFormCheckbox = (e, name) => {
    const { checked } = e.target;
    const input = form.find((item) => item.name === name);
    const filter = form.filter((item) => item.name !== name);
    input.value = checked.toString();
    setForm([...filter, input]);
    setError(null);
  };

  return (
    <>
      <div
        className={cn('contact-images')}
        style={{ background: `url(${contact})` }}
      >
        <div className="container container-response">
          <div className="contact-panel">
            <h1>{t('Contact.title')}</h1>
            <div className="d-flex align-items-center justify-content-between flex-response">
              <div className="form">
                <input
                  type="text"
                  className="contact-input"
                  placeholder={t('About.form.name')}
                  onChange={(e) => handlerFormInput(e, 'name')}
                  value={getDefaultValue('name')}
                />
                <input
                  type="text"
                  className="contact-input"
                  placeholder={t('About.form.email')}
                  onChange={(e) => handlerFormInput(e, 'email')}
                  value={getDefaultValue('email')}
                />
                <textarea
                  cols={5}
                  rows={5}
                  className="contact-textarea"
                  placeholder={t('About.form.message')}
                  onChange={(e) => handlerFormInput(e, 'message')}
                  value={getDefaultValue('message')}
                />
                <label className="d-flex gap-2">
                  <input
                    className="about-checkbox"
                    type="checkbox"
                    onChange={(e) => handlerFormCheckbox(e, 'robot')}
                    checked={getDefaultChecked('robot')}
                  />
                  {t('About.form.robot')}
                </label>
                {error && (
                  <div className="alert-about alert alert-danger text-center">
                    {error}
                  </div>
                )}
                {success && (
                  <SuccessForm
                    text={t('About.form.success')}
                    subtext={t('About.form.success-bottom')}
                  />
                )}
              </div>
              <Info />
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ height: 0 }}>
        <div className="button-panel">
          <button className="contact-button" onClick={handlerForm}>
            {t('About.form.send')}
          </button>
        </div>
      </div>
    </>
  );
};
