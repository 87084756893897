import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Areas } from '../components/Areas';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { HelloImages } from '../components/HelloImages';

export const Area = (): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Title.Area');
  }, [t]);

  return (
    <>
      <Header />
      <HelloImages title={t('HelloImage.Area')} img="area" />
      <Areas />
      <Footer />
    </>
  );
};
