import { useTranslation } from 'react-i18next';

import { AreaAbout } from '../components/Areas/ui/AreaAbout/AreaAbout';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { HelloImages } from '../components/HelloImages';

export const AboutArea = (): JSX.Element => {
  const { t } = useTranslation();
  const getId = () => window.location.search?.split('=')[1];
  const area = [
    { id: '1', title: t('Location.title.marriot') },
    { id: '2', title: t('Location.title.ramada') },
    { id: '3', title: t('Location.title.art') },
    { id: '4', title: t('Location.title.degas') },
    { id: '5', title: t('Location.title.benefit') },
    { id: '6', title: t('Location.title.mercure') },
    { id: '7', title: t('Location.title.yar') },
    { id: '8', title: t('Location.title.passag') },
    { id: '9', title: t('Location.title.express') },
    { id: '10', title: t('Location.title.amaks') },
    { id: '11', title: t('Location.title.azimut') },
    { id: '12', title: t('Location.title.hampton') },
    { id: '13', title: t('Location.title.expo') },
    { id: '14', title: t('Location.title.texs') },
    { id: '15', title: t('Location.title.inc') },
    { id: '16', title: t('Location.title.taiga') },
    { id: '17', title: t('Location.title.scor') },
    { id: '18', title: t('Location.title.les') },
  ];

  return (
    <>
      <Header />
      <HelloImages
        title={area.find((item) => item.id === getId())?.title}
        img="area"
      />
      <AreaAbout id={getId()} />;
      <Footer />
    </>
  );
};
