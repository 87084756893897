import { useTranslation } from 'react-i18next';

import pref1 from '../../../assets/images/Main/pref1.png';
import pref2 from '../../../assets/images/Main/pref2.png';
import pref3 from '../../../assets/images/Main/pref3.png';
import pref4 from '../../../assets/images/Main/pref4.png';
import { findAndReplaceBreak } from '../../../shared/utils/findAndReplaceBreak';

interface ICardItem {
  title: string;
  text: string;
  images: string;
}

export const MainPreference = (): JSX.Element => {
  const { t } = useTranslation();

  const preferenceMap = [
    {
      id: 1,
      title: t('Main.preference.card1.title'),
      text: t('Main.preference.card1.text'),
      images: pref1,
    },
    {
      id: 2,
      title: t('Main.preference.card2.title'),
      text: t('Main.preference.card2.text'),
      images: pref2,
    },
    {
      id: 3,
      title: t('Main.preference.card3.title'),
      text: t('Main.preference.card3.text'),
      images: pref3,
    },
    {
      id: 4,
      title: t('Main.preference.card4.title'),
      text: t('Main.preference.card4.text'),
      images: pref4,
    },
  ];

  const CardItem = ({ title, text, images }: ICardItem): JSX.Element => (
    <div className="card_index">
      <img className="hover_yellow" src={images} alt="" />
      <p className="mb-0 yellow_titless">{title}</p>
      <p className="mb-0 yellow_subtitles">{findAndReplaceBreak(text)}</p>
    </div>
  );

  return (
    <div className="main-preference">
      <div className="container pb-5">
        <p className="about-title text-center pt-5 pb-2">
          {t('Main.preference.title')}
        </p>

        <div className="d-flex flex-wrap flex-preference pt-5">
          {preferenceMap.map(({ id, title, text, images }) => (
            <CardItem key={id} title={title} text={text} images={images} />
          ))}
        </div>
      </div>
    </div>
  );
};
