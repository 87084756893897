import { ServicesItems } from './ServicesItems';
import { findAndReplaceBreak } from '../../../shared/utils/findAndReplaceBreak';

interface IProps {
  title: string;
  child: { id: number; title: string }[];
}

export const ServicesTitle = ({ title, child }: IProps): JSX.Element => (
  <div className="item-wrap d-grid text-center">
    <p className="title mb-0">{findAndReplaceBreak(title)}</p>
    {child.map(({ id, title }) => (
      <ServicesItems key={id} id={id} title={title} />
    ))}
  </div>
);
