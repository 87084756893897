import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Contacts } from '../components/Contacts';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

export const Contact = (): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Title.Contact');
  }, [t]);

  return (
    <>
      <Header />
      <Contacts />
      <div id="kontLayer1">
        <iframe
          // eslint-disable-next-line max-len
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A87a63f445832e09049311d8f80c616a271ca8dc8565d007e4f0d8bca1a51385e&amp;source=constructor"
          width="100%"
          height="700"
          frameBorder="0"
          style={{ pointerEvents: 'none' }}
        />
      </div>
      <Footer />
    </>
  );
};
