import '../Footer.scss';

export const Copyright = (): JSX.Element => (
  <footer>
    <div className="container">
      <p className="copyright-text">
        Copyright © {`${new Date().getFullYear()}`}. All rights reserved.
      </p>
    </div>
  </footer>
);
