import cn from 'classname';
import { useTranslation } from 'react-i18next';

import '../Footer.scss';

export const Navigation = (): JSX.Element => {
  const { t } = useTranslation();

  const navigation_map = [
    { id: 1, title: t('Navigation.About'), href: '/about' },
    { id: 2, title: t('Navigation.Service'), href: '/service' },
    { id: 3, title: t('Navigation.Project'), href: '/project' },
    { id: 4, title: t('Navigation.Area'), href: '/area' },
    { id: 5, title: t('Navigation.Contact'), href: '/contact' },
  ];

  return (
    <nav className="navigation">
      {navigation_map.map(({ id, title, href }) => (
        <a
          key={id}
          className={cn(
            'navigation-item hover',
            href === window.location.pathname && 'active',
          )}
          href={href}
        >
          {title}
        </a>
      ))}
    </nav>
  );
};
