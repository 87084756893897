import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Main } from '../components/Main';

export const Home = (): JSX.Element => (
  <>
    <Header />
    <Main />
    <Footer />
  </>
);
