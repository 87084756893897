import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { HelloImages } from '../components/HelloImages';
import { ServiceAbout } from '../components/Services/ui/ServiceAbout/ServiceAbout';

export const AboutService = (): JSX.Element => {
  const { t } = useTranslation();

  const getId = () => window.location.search?.split('=')[1];

  const areaAbout = [
    { id: '1', text: t('Service.card1.item1') },
    { id: '2', text: t('Service.card1.item2') },
    { id: '3', text: t('Service.card1.item3') },
    { id: '4', text: t('Service.card1.item4') },
    { id: '5', text: t('Service.card2.item1') },
    { id: '6', text: t('Service.card2.item2') },
    { id: '7', text: t('Service.card2.item3') },
    { id: '8', text: t('Service.card2.item4') },
    { id: '9', text: t('Service.card3.item1') },
    { id: '10', text: t('Service.card3.item2') },
    { id: '11', text: t('Service.card3.item3') },
    { id: '12', text: t('Service.card3.item4') },
  ];

  return (
    <>
      <Header />
      <HelloImages
        title={areaAbout.find((item) => item.id === getId())?.text}
        img="service"
      />
      <ServiceAbout id={getId()} />;
      <div className="send-to-amo">
        <Link className="btn-send-amo" to={'/#amo1'}>
          {t('Service.sendAmo')}
        </Link>
      </div>
      <Footer />
    </>
  );
};
