import { useTranslation } from 'react-i18next';

import '../Footer.scss';

import { ReactComponent as Vk } from '../../../../assets/images/Footer/vk.svg';

export const FollowMe = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="follow-me">
      <div className="follow-me-title">{t('Footer.followme')}</div>
      <div className="follow-me-image-wrapper">
        <a className="follow-me-image hover" href="/">
          <Vk />
        </a>
      </div>
    </div>
  );
};
