import { Link } from 'react-router-dom';

import { findAndReplaceBreak } from '../../../shared/utils/findAndReplaceBreak';

interface IProps {
  title: string;
  id: number;
}

export const ServicesItems = ({ id, title }: IProps): JSX.Element => (
  <Link to={`/about-service?id=${id}`} className="items">
    {findAndReplaceBreak(title)}
  </Link>
);
