import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import call from '../../../../assets/images/Footer/call-me.png';
import { AboutForm } from '../../../Abouts/ui/AboutForm';

import '../Footer.scss';

export const Call = (): JSX.Element => {
  const [form, setForm] = useState(false);
  const { t } = useTranslation();

  const CallForm = (): JSX.Element => (
    <div className="call_from">
      <div className="form-wrapper">
        <AboutForm callback={setForm} />
      </div>
    </div>
  );

  return (
    <>
      {form && <CallForm />}
      <div className="call-me-please">
        <div className="call-me-please-title">{t('Footer.call')}</div>
        <div className="call-me-please-image-wrapper">
          <img
            onClick={() => setForm(true)}
            className="call-me-please-image hover"
            src={call}
            alt="Заказать звонок"
          />
        </div>
      </div>
    </>
  );
};
