import { useTranslation } from 'react-i18next';

import area from '../../../assets/images/Contact/area.png';
import mail from '../../../assets/images/Contact/mail.png';
import phone from '../../../assets/images/Contact/phone.png';
interface IMobile {
  mobile?: boolean;
}

export const Info = ({ mobile }: IMobile): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="contact-info">
      {!mobile && (
        <div className="icon-contact">
          <img src={area} alt="location" />
        </div>
      )}

      <p className="contact-info-text">{t('Contact.contact.line1')}</p>
      <p className="contact-info-text">{t('Contact.contact.line2')}</p>
      {!mobile && (
        <div className="icon-contact">
          <img src={phone} alt="phone" />
        </div>
      )}

      <p className="contact-info-text text-center">+7 473 333 59 95</p>
      <p className="contact-info-text text-center">+7 930 408 23 71</p>
      <p className="contact-info-text text-center">+7 903 651 11 43</p>
      {!mobile && (
        <div className="icon-contact">
          <img src={mail} alt="mail" />
        </div>
      )}

      <p className="contact-info-text text-center">info@event-360.ru</p>
    </div>
  );
};
