import { useTranslation } from 'react-i18next';

import '../Header.scss';

export const CityComponents = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="d-grid d-none-991">
      <p className="mb-0 header-city">{t('City.vrn')}</p>
      <p className="mb-0 header-city">{t('City.lps')}</p>
      <p className="mb-0 header-city">{t('City.blg')}</p>
    </div>
  );
};
