import { Copyright } from './ui/Copyright';
import { FooterComponent } from './ui/FooterComponent';
import './Footer.scss';

export const Footer = (): JSX.Element => (
  <>
    <FooterComponent />
    <Copyright />
  </>
);
