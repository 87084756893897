import '../Header.scss';
import cn from 'classname';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const LangSwitcher = (): JSX.Element => {
  const langReset = {
    ru: false,
    en: false,
    zh: false,
  };

  const [lang, setLang] = useState({
    ru: true,
    en: false,
    zh: false,
  });

  useEffect(() => {
    const find = {
      ...langReset,
      [window.localStorage.getItem('language')]: true,
    };
    setLang(find);
  }, []);

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const find = { ...langReset, [lng]: true };
    setLang(find);
    window.localStorage.setItem('language', lng);
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <div className="d-flex align-items-center justify-content-around">
      <p
        className={cn(
          'mb-0 me-3 header-lang header-hovered',
          lang.ru && 'active',
        )}
        onClick={() => changeLanguage('ru')}
      >
        <b>RUS</b>
      </p>
      <p
        className={cn(
          'mb-0 me-3 header-lang header-hovered',
          lang.en && 'active',
        )}
        onClick={() => changeLanguage('en')}
      >
        <b>ENG</b>
      </p>
      <p
        className={cn('mb-0 header-lang header-hovered', lang.zh && 'active')}
        onClick={() => changeLanguage('zh')}
      >
        <b>中文</b>
      </p>
    </div>
  );
};
