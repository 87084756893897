import { useTranslation } from 'react-i18next';

import './Service.scss';
import { ServicesTitle } from './ServicesTitle';

export const Services = (): JSX.Element => {
  const { t } = useTranslation();

  const serviceMap = [
    {
      id: 1,
      title: t('Service.card1.title'),
      child: [
        { id: 1, title: t('Service.card1.item1') },
        { id: 2, title: t('Service.card1.item2') },
        { id: 3, title: t('Service.card1.item3') },
        { id: 4, title: t('Service.card1.item4') },
      ],
    },
    {
      id: 2,
      title: t('Service.card2.title'),
      child: [
        { id: 5, title: t('Service.card2.item1') },
        { id: 6, title: t('Service.card2.item2') },
        { id: 7, title: t('Service.card2.item3') },
        { id: 8, title: t('Service.card2.item4') },
      ],
    },
    {
      id: 3,
      title: t('Service.card3.title'),
      child: [
        { id: 9, title: t('Service.card3.item1') },
        { id: 10, title: t('Service.card3.item2') },
        { id: 11, title: t('Service.card3.item3') },
        { id: 12, title: t('Service.card3.item4') },
      ],
    },
  ];

  return (
    <div className="container">
      <div className="d-flex d-flex-service align-items-center justify-content-around mt-5 mb-5">
        {serviceMap.map(({ id, title, child }) => (
          <ServicesTitle key={id} title={title} child={child} />
        ))}
      </div>
    </div>
  );
};
