import { Navigation } from './Navigation';
import { ReactComponent as Close } from '../../../assets/images/Header/close.svg';
import { Info } from '../../Contacts/ui/Info';
import { LangSwitcher } from '../../Header/ui/ui/LangSwitcher';

import './MobileMenu.scss';

interface IProps {
  show: boolean;
  setShow: (show) => void;
}

export const MobileMenu = ({ show, setShow }: IProps): JSX.Element =>
  show && (
    <div className="mobile-menu">
      <div className="langs">
        <LangSwitcher />
      </div>
      <Navigation show={show} setShow={setShow} />
      <div className="close-menu" onClick={() => setShow(!show)}>
        <Close />
      </div>
      <div className="nav-info">
        <Info mobile={true} />
      </div>
    </div>
  );
