import cn from 'classname';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';

import { findAndReplaceBreak } from '../../../shared/utils/findAndReplaceBreak';

interface ICardItem {
  id: number;
  title: string;
  text: string;
  button: string;
  href: string;
}

export const MainProject = (): JSX.Element => {
  const { t } = useTranslation();

  const cardMap = [
    {
      id: 1,
      href: 'medconf',
      title: t('Main.project.card1.title'),
      text: t('Main.project.card1.text'),
      button: t('Main.project.button'),
    },
    {
      id: 2,
      href: 'regata',
      title: t('Main.project.card2.title'),
      text: t('Main.project.card2.text'),
      button: t('Main.project.button'),
    },
    {
      id: 3,
      href: 'awards',
      title: t('Main.project.card3.title'),
      text: t('Main.project.card3.text'),
      button: t('Main.project.button'),
    },
    {
      id: 4,
      href: 'tradeshow',
      title: t('Main.project.card4.title'),
      text: t('Main.project.card4.text'),
      button: t('Main.project.button'),
    },
    {
      id: 5,
      href: 'mission',
      title: t('Main.project.card5.title'),
      text: t('Main.project.card5.text'),
      button: t('Main.project.button'),
    },
    {
      id: 6,
      href: 'maksim',
      title: t('Main.project.card6.title'),
      text: t('Main.project.card6.text'),
      button: t('Main.project.button'),
    },
    {
      id: 7,
      href: 'intensive',
      title: t('Main.project.card7.title'),
      text: t('Main.project.card7.text'),
      button: t('Main.project.button'),
    },
    {
      id: 8,
      href: 'guangzhou',
      title: t('Main.project.card8.title'),
      text: t('Main.project.card8.text'),
      button: t('Main.project.button'),
    },
  ];

  const CardItem = ({
    id,
    title,
    text,
    href,
    button,
  }: ICardItem): JSX.Element => (
    <div className={cn('item_project item') + id}>
      <div className="inset_project">
        <p className="begin_project">
          <strong>{findAndReplaceBreak(title)}</strong>
        </p>
        <p className="second_project">{text}</p>
        <Link to={`/project#${href}`} className="btns">
          {button}
        </Link>
      </div>
    </div>
  );

  return (
    <div className="container">
      <p className="about-title text-center pt-5 pb-2">
        {t('Main.project.title')}
      </p>
      <div className="wrap_project_view">
        {cardMap.map(({ id, title, text, href, button }) => (
          <CardItem
            key={id}
            id={id}
            title={title}
            text={text}
            href={href}
            button={button}
          />
        ))}
      </div>
    </div>
  );
};
