import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.scss';
import { About } from './page/About';
import { AboutArea } from './page/AboutArea';
import { AboutService } from './page/AboutService';
import { Area } from './page/Area';
import { Contact } from './page/Contact';
import { Home } from './page/Home';
import { Project } from './page/Project';
import { Service } from './page/Service';

enum pageEnum {
  HOME = '/',
  ABOUT = '/about',
  SERVICE = '/service',
  ABOUT_SERVICE = '/about-service',
  PROJECT = '/project',
  AREA = '/area',
  ABOUT_AREA = '/area-about',
  CONTACT = '/contact',
}

const router = createBrowserRouter([
  {
    path: pageEnum.HOME,
    element: <Home />,
  },
  {
    path: pageEnum.ABOUT,
    element: <About />,
  },
  {
    path: pageEnum.ABOUT_AREA,
    element: <AboutArea />,
  },
  {
    path: pageEnum.SERVICE,
    element: <Service />,
  },
  {
    path: pageEnum.ABOUT_SERVICE,
    element: <AboutService />,
  },
  {
    path: pageEnum.PROJECT,
    element: <Project />,
  },
  {
    path: pageEnum.AREA,
    element: <Area />,
  },
  {
    path: pageEnum.CONTACT,
    element: <Contact />,
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <RouterProvider router={router} />,
);
