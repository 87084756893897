import { ReactNode } from 'react';

export const findAndReplaceBreak = (
  text: string,
  margin?: boolean,
): ReactNode[] =>
  text?.split('\n').map((item, index) => (
    <p className={margin ? 'mb-2' : 'mb-0'} key={index}>
      {item}
    </p>
  ));
