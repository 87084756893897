import { AboutHeader } from './ui/AboutHeader';
import { BurgerComponent } from './ui/Burger';
import { LogoComponents } from './ui/LogoComponents';
import { NavigationPanel } from './ui/NavigationPanel';

import './Header.scss';

export const Header = (): JSX.Element => (
  <header>
    <div className="header-container container">
      <div className="header-wrapper">
        <LogoComponents href="/" />
        <AboutHeader />
        <BurgerComponent />
      </div>
      <NavigationPanel />
    </div>
  </header>
);
