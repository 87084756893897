import '../Footer.scss';

import { useEffect, useState } from 'react';

import { ReactComponent as Logo } from '../../../../assets/images/Footer/logo.svg';
import { ReactComponent as LogoEN } from '../../../../assets/images/Footer/logo_en.svg';

export const LogoFooter = (): JSX.Element => {
  const [language, setLanguage] = useState(
    window.localStorage.getItem('language'),
  );

  const handlerStorage = () =>
    setLanguage(window.localStorage.getItem('language'));

  useEffect(() => {
    window.addEventListener('storage', handlerStorage);

    return () => {
      window.removeEventListener('storage', handlerStorage);
    };
  }, []);

  const imageMap = {
    ru: <Logo className="logo" />,
    en: <LogoEN className="logo" />,
    zh: <LogoEN className="logo" />,
  };

  return (
    <div className="footer-logo">
      {language ? imageMap[language] : imageMap['ru']}
    </div>
  );
};
