import { use } from 'i18next';
import { Fragment, ReactElement, ReactNode } from 'react';
import { initReactI18next } from 'react-i18next';

import locales from '../locales';

const language = window.localStorage.getItem('language');

const getLang = () => (language ? [language.replaceAll("'", '')] : ['ru']);

use(initReactI18next).init({
  resources: locales,
  fallbackLng: getLang(),
  interpolation: {
    escapeValue: false,
  },
});

interface TranslationProviderProps {
  children: ReactNode;
}

export const TranslationProvider = ({
  children,
}: TranslationProviderProps): ReactElement | null => (
  <Fragment>{children}</Fragment>
);
