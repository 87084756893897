import { useTranslation } from 'react-i18next';

export const AboutHome = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="home-about">
      <div className="home-about-text">
        <p className="about-title">{t('About.title')}</p>
        <p className="mb-0">
          <b>{t('About.about.line1')}</b> {t('About.about.line2')}
        </p>
        <p className="mb-0">
          <b>{t('About.about.line3')}</b> {t('About.about.line4')}
        </p>
        <p className="mb-0">
          <b>{t('About.about.line5')}</b> {t('About.about.line6')}
        </p>
        <p className="mb-0">
          {t('About.about.line7')} <b>{t('About.about.line8')}</b>{' '}
          {t('About.about.line9')}
        </p>
        <p className="mb-0">{t('About.about.line10')}</p>
        <p className="mb-0">
          <b>{t('About.about.line11')}</b>
        </p>
      </div>
    </div>
  );
};
