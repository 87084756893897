import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './About.scss';
import { AboutForm } from './AboutForm';
import img9 from '../../../assets/images/About/images-docs/10.jpg';
import img10 from '../../../assets/images/About/images-docs/11.jpg';
import img11 from '../../../assets/images/About/images-docs/12.jpg';
import img12 from '../../../assets/images/About/images-docs/13.jpg';
import img13 from '../../../assets/images/About/images-docs/14.jpg';
import img14 from '../../../assets/images/About/images-docs/15.jpg';
import img15 from '../../../assets/images/About/images-docs/16.jpg';
import img16 from '../../../assets/images/About/images-docs/17.jpg';
import img17 from '../../../assets/images/About/images-docs/18.jpg';
import img18 from '../../../assets/images/About/images-docs/19.jpg';
import img1 from '../../../assets/images/About/images-docs/2.jpg';
import img19 from '../../../assets/images/About/images-docs/20.jpg';
import img20 from '../../../assets/images/About/images-docs/21.jpg';
import img21 from '../../../assets/images/About/images-docs/22.jpg';
import img22 from '../../../assets/images/About/images-docs/23.jpg';
import img23 from '../../../assets/images/About/images-docs/24.jpg';
import img24 from '../../../assets/images/About/images-docs/25.jpg';
import img25 from '../../../assets/images/About/images-docs/26.jpg';
import img26 from '../../../assets/images/About/images-docs/27.jpg';
import img2 from '../../../assets/images/About/images-docs/3.jpg';
import img3 from '../../../assets/images/About/images-docs/4.jpg';
import img4 from '../../../assets/images/About/images-docs/5.jpg';
import img5 from '../../../assets/images/About/images-docs/6.jpg';
import img6 from '../../../assets/images/About/images-docs/7.jpg';
import img7 from '../../../assets/images/About/images-docs/8.jpg';
import img8 from '../../../assets/images/About/images-docs/9.jpg';

export const Abouts = (): JSX.Element => {
  const { t } = useTranslation();

  const [slider, setSlider] = useState(null);

  const sliderMap = [
    { id: 1, images: img1 },
    { id: 2, images: img2 },
    { id: 3, images: img3 },
    { id: 4, images: img4 },
    { id: 5, images: img5 },
    { id: 6, images: img6 },
    { id: 7, images: img7 },
    { id: 8, images: img8 },
    { id: 9, images: img9 },
    { id: 10, images: img10 },
    { id: 11, images: img11 },
    { id: 12, images: img12 },
    { id: 13, images: img13 },
    { id: 14, images: img14 },
    { id: 15, images: img15 },
    { id: 16, images: img16 },
    { id: 17, images: img17 },
    { id: 18, images: img18 },
    { id: 19, images: img19 },
    { id: 20, images: img20 },
    { id: 21, images: img21 },
    { id: 22, images: img22 },
    { id: 23, images: img23 },
    { id: 24, images: img24 },
    { id: 25, images: img25 },
    { id: 26, images: img26 },
  ];

  const handlerSlider = (id: number) => {
    setSlider(id);
  };

  const handlerPrev = () => {
    if (slider !== 1) {
      setSlider((prev) => prev - 1);
    } else if (slider === 1) {
      setSlider(26);
    }
  };

  const handlerNext = () => {
    if (slider !== 26) {
      setSlider((prev) => prev + 1);
    } else if (slider === 26) {
      setSlider(1);
    }
  };

  return (
    <>
      <div className="about-wrapper d-flex flex-wrap">
        <div className="about-images" />
        <div className="d-grid w-50 about-text-wrapper">
          <p className="about-title">{t('About.title')}</p>
          <p className="about-text">
            <b>{t('About.about.line1')}</b> {t('About.about.line2')}
          </p>
          <p className="about-text">
            <b>{t('About.about.line3')}</b> {t('About.about.line4')}
          </p>
          <p className="about-text">
            <b>{t('About.about.line5')}</b> {t('About.about.line6')}
          </p>
          <p className="about-text">
            {t('About.about.line7')} <b>{t('About.about.line8')}</b>{' '}
            {t('About.about.line9')}
          </p>
          <p className="about-text">{t('About.about.line10')}</p>
          <p className="about-text">
            <b>{t('About.about.line11')}</b>
          </p>
        </div>
      </div>
      <div className="container pt-5 pb-5">
        <p className="about-title text-center">{t('About.thanks')}</p>
        <div className="d-flex flex-wrap gap-2 justify-content-evenly align-content-center">
          {sliderMap.map(({ id, images }) => (
            <img
              key={id}
              onClick={() => handlerSlider(id)}
              className="about-diplom"
              src={images}
              alt=""
            />
          ))}
        </div>
      </div>
      {slider !== null && (
        <div className="slider">
          <div className="close-slider" onClick={() => setSlider(null)}>
            {t('About.slider.close')}
          </div>
          <img
            className="about-diplom-slide"
            src={sliderMap.find((item) => item.id === slider).images}
            alt=""
          />
          <div className="control">
            <div onClick={handlerPrev}>{t('About.slider.prev')}</div>
            <div onClick={handlerNext}>{t('About.slider.next')}</div>
          </div>
        </div>
      )}
      <div className="container pt-5 pb-5">
        <p className="about-title text-center pb-5">{t('About.pay')}</p>
        <div className="d-flex justify-content-evenly about-download">
          <a
            href="download/event360_brif.docx"
            download="Бриф на проведение мероприятия Event-360"
            className="download-button"
          >
            {t('About.button.brif')}
          </a>
          <a
            href="download/event360_present.pdf"
            download="Презентация Event-360"
            className="download-button"
          >
            {t('About.button.present')}
          </a>
        </div>
      </div>
      <AboutForm />
    </>
  );
};
