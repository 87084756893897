import { useTranslation } from 'react-i18next';

export const MainPartners = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <p className="about-title text-center pt-5 pb-2">
        {t('Main.partners.title')}
      </p>
      <div className="main-partner">
        <div className="imgme">
          <img src="rows/row1 (1).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row1 (2).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row1 (3).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row1 (4).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row1 (5).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row1 (6).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row1 (7).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row1 (8).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row2 (1).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row2 (2).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row2 (3).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row2 (4).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row2 (5).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row2 (6).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row2 (7).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row2 (8).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row3 (1).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row3 (2).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row3 (3).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row3 (4).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row3 (5).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row3 (6).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row3 (7).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row3 (8).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row4 (1).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row4 (2).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row4 (3).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row4 (4).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row4 (5).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row4 (6).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row4 (7).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row4 (8).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row5 (1).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row5 (2).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row5 (3).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row5 (4).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row5 (5).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row5 (6).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row5 (7).jpg" className="logo-img" />
        </div>
        <div className="imgme">
          <img src="rows/row5 (8).jpg" className="logo-img" />
        </div>
      </div>
    </>
  );
};
