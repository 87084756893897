import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { findAndReplaceBreak } from '../../../shared/utils/findAndReplaceBreak';
import './Projects.scss';

interface IProps {
  id?: string;
  title: string;
  date: string;
  text: string;
  images: string;
}

export const ProjectsItem = ({
  id,
  title,
  date,
  text,
  images,
}: IProps): JSX.Element => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  return (
    <div className="container" id={id}>
      <div className="project-wrapper">
        <img className="images-project" src={images} />

        <div className={show ? 'text-block active' : 'text-block'}>
          <p className="title-variant">
            <b>{title}</b>
          </p>
          <p>{date}</p>
          <p>{findAndReplaceBreak(text, true)}</p>
          <div
            className={show ? 'hidden' : 'shadows'}
            onClick={() => setShow(!show)}
          >
            {show ? t('Project.button.collapse') : t('Project.button.next')}
          </div>
        </div>
      </div>
    </div>
  );
};
