import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import amaks1 from '../../../../assets/images/AreaAbout/amaks (1).jpg';
import amaks2 from '../../../../assets/images/AreaAbout/amaks (2).jpg';
import amaks3 from '../../../../assets/images/AreaAbout/amaks (3).jpg';
import amaks4 from '../../../../assets/images/AreaAbout/amaks (4).jpg';
import arthotel1 from '../../../../assets/images/AreaAbout/arthotel (1).jpg';
import arthotel2 from '../../../../assets/images/AreaAbout/arthotel (2).jpg';
import arthotel3 from '../../../../assets/images/AreaAbout/arthotel (3).jpg';
import arthotel4 from '../../../../assets/images/AreaAbout/arthotel (4).jpg';
import azimut1 from '../../../../assets/images/AreaAbout/Azimut2.jpg';
import azimut2 from '../../../../assets/images/AreaAbout/Azimut3.jpg';
import azimut3 from '../../../../assets/images/AreaAbout/Azimut4.jpg';
import Benefit2 from '../../../../assets/images/AreaAbout/Benefit plaza 2.jpg';
import Benefit3 from '../../../../assets/images/AreaAbout/Benefit plaza 3.jpg';
import Benefit4 from '../../../../assets/images/AreaAbout/Benefit plaza 4.jpg';
import Benefit1 from '../../../../assets/images/AreaAbout/Benefit plaza.jpg';
import businesink1 from '../../../../assets/images/AreaAbout/businesink (1).jpg';
import businesink2 from '../../../../assets/images/AreaAbout/businesink (2).jpg';
import businesink3 from '../../../../assets/images/AreaAbout/businesink (3).jpg';
import businesink4 from '../../../../assets/images/AreaAbout/businesink (4).jpg';
import degas1 from '../../../../assets/images/AreaAbout/Degas 1.jpg';
import degas2 from '../../../../assets/images/AreaAbout/Degas 2.jpg';
import degas3 from '../../../../assets/images/AreaAbout/Degas 3.jpg';
import degas4 from '../../../../assets/images/AreaAbout/Degas 4.jpg';
import donforest1 from '../../../../assets/images/AreaAbout/donforest (1).jpg';
import donforest2 from '../../../../assets/images/AreaAbout/donforest (2).jpg';
import donforest3 from '../../../../assets/images/AreaAbout/donforest (3).jpg';
import donforest4 from '../../../../assets/images/AreaAbout/donforest (4).jpg';
import expo2 from '../../../../assets/images/AreaAbout/expoeventhall 2.jpg';
import expo3 from '../../../../assets/images/AreaAbout/expoeventhall 3.jpg';
import expo4 from '../../../../assets/images/AreaAbout/expoeventhall 4.jpg';
import expo1 from '../../../../assets/images/AreaAbout/expoeventhall.jpg';
import hampton2 from '../../../../assets/images/AreaAbout/Hampton-inn-voronezh 2.jpg';
import hampton3 from '../../../../assets/images/AreaAbout/Hampton-inn-voronezh 3.jpg';
import hampton1 from '../../../../assets/images/AreaAbout/Hampton-inn-voronezh.jpg';
import holliday2 from '../../../../assets/images/AreaAbout/Holiday in 2.jpg';
import holliday3 from '../../../../assets/images/AreaAbout/Holiday in 3.jpg';
import holliday1 from '../../../../assets/images/AreaAbout/Holiday in.jpg';
import mariot1 from '../../../../assets/images/AreaAbout/marriott (1).jpg';
import mariot2 from '../../../../assets/images/AreaAbout/marriott (2).jpg';
import mariot3 from '../../../../assets/images/AreaAbout/marriott (3).jpg';
import mariot4 from '../../../../assets/images/AreaAbout/marriott (4).jpg';
import Mercure2 from '../../../../assets/images/AreaAbout/Mercure 2.jpg';
import Mercure3 from '../../../../assets/images/AreaAbout/Mercure 3.jpg';
import Mercure4 from '../../../../assets/images/AreaAbout/Mercure 4.jpg';
import Mercure1 from '../../../../assets/images/AreaAbout/Mercure.jpg';
import passga1 from '../../../../assets/images/AreaAbout/passga (1).jpg';
import passga2 from '../../../../assets/images/AreaAbout/passga (2).jpg';
import passga3 from '../../../../assets/images/AreaAbout/passga (3).jpg';
import Ramada2 from '../../../../assets/images/AreaAbout/Ramada 1.jpg';
import Ramada3 from '../../../../assets/images/AreaAbout/Ramada 2.jpg';
import Ramada4 from '../../../../assets/images/AreaAbout/Ramada 3.jpg';
import Ramada1 from '../../../../assets/images/AreaAbout/Ramada.jpg';
import skor1 from '../../../../assets/images/AreaAbout/skor (1).jpg';
import skor2 from '../../../../assets/images/AreaAbout/skor (2).jpg';
import skor3 from '../../../../assets/images/AreaAbout/skor (3).jpg';
import skor4 from '../../../../assets/images/AreaAbout/skor (4).jpg';
import taiga1 from '../../../../assets/images/AreaAbout/taiga (1).jpg';
import taiga2 from '../../../../assets/images/AreaAbout/taiga (2).jpg';
import taiga3 from '../../../../assets/images/AreaAbout/taiga (3).jpg';
import taiga4 from '../../../../assets/images/AreaAbout/taiga (4).jpg';
import tekstyle1 from '../../../../assets/images/AreaAbout/tekstyle (1).jpg';
import tekstyle2 from '../../../../assets/images/AreaAbout/tekstyle (2).jpg';
import tekstyle3 from '../../../../assets/images/AreaAbout/tekstyle (3).jpg';
import tekstyle4 from '../../../../assets/images/AreaAbout/tekstyle (4).jpg';
import yar1 from '../../../../assets/images/AreaAbout/yar (1).jpg';
import yar2 from '../../../../assets/images/AreaAbout/yar (2).jpg';
import yar3 from '../../../../assets/images/AreaAbout/yar (3).jpg';
import yar4 from '../../../../assets/images/AreaAbout/yar (4).jpg';
import { findAndReplaceBreak } from '../../../../shared/utils/findAndReplaceBreak';

interface IProps {
  id?: string;
}

export const AreaAbout = ({ id }: IProps): JSX.Element => {
  const { t } = useTranslation();

  const areaAbout = [
    {
      id: '1',
      text: t('Location.about.marriot'),
      images: [
        { id: 1, images: mariot1 },
        { id: 2, images: mariot2 },
        { id: 3, images: mariot3 },
        { id: 4, images: mariot4 },
      ],
    },
    {
      id: '2',
      text: t('Location.about.ramada'),
      images: [
        { id: 1, images: Ramada1 },
        { id: 2, images: Ramada2 },
        { id: 3, images: Ramada3 },
        { id: 4, images: Ramada4 },
      ],
    },
    {
      id: '3',
      text: t('Location.about.art'),
      images: [
        { id: 1, images: arthotel1 },
        { id: 2, images: arthotel2 },
        { id: 3, images: arthotel3 },
        { id: 4, images: arthotel4 },
      ],
    },
    {
      id: '4',
      text: t('Location.about.degas'),
      images: [
        { id: 1, images: degas1 },
        { id: 2, images: degas2 },
        { id: 3, images: degas3 },
        { id: 4, images: degas4 },
      ],
    },
    {
      id: '5',
      text: t('Location.about.benefit'),
      images: [
        { id: 1, images: Benefit1 },
        { id: 2, images: Benefit2 },
        { id: 3, images: Benefit3 },
        { id: 4, images: Benefit4 },
      ],
    },
    {
      id: '6',
      text: t('Location.about.mercure'),
      images: [
        { id: 1, images: Mercure1 },
        { id: 2, images: Mercure2 },
        { id: 3, images: Mercure3 },
        { id: 4, images: Mercure4 },
      ],
    },
    {
      id: '7',
      text: t('Location.about.yar'),
      images: [
        { id: 1, images: yar1 },
        { id: 2, images: yar2 },
        { id: 3, images: yar3 },
        { id: 4, images: yar4 },
      ],
    },
    {
      id: '8',
      text: t('Location.about.passag'),
      images: [
        { id: 1, images: passga1 },
        { id: 2, images: passga2 },
        { id: 3, images: passga3 },
      ],
    },
    {
      id: '9',
      text: t('Location.about.express'),
      images: [
        { id: 1, images: holliday1 },
        { id: 2, images: holliday2 },
        { id: 3, images: holliday3 },
      ],
    },
    {
      id: '10',
      text: t('Location.about.amaks'),
      images: [
        { id: 1, images: amaks1 },
        { id: 2, images: amaks2 },
        { id: 3, images: amaks3 },
        { id: 4, images: amaks4 },
      ],
    },
    {
      id: '11',
      text: t('Location.about.azimut'),
      images: [
        { id: 1, images: azimut1 },
        { id: 2, images: azimut2 },
        { id: 3, images: azimut3 },
      ],
    },
    {
      id: '12',
      text: t('Location.about.hampton'),
      images: [
        { id: 1, images: hampton1 },
        { id: 2, images: hampton2 },
        { id: 3, images: hampton3 },
      ],
    },
    {
      id: '13',
      text: t('Location.about.expo'),
      images: [
        { id: 1, images: expo1 },
        { id: 2, images: expo2 },
        { id: 3, images: expo3 },
        { id: 4, images: expo4 },
      ],
    },
    {
      id: '14',
      text: t('Location.about.texs'),
      images: [
        { id: 1, images: tekstyle1 },
        { id: 2, images: tekstyle2 },
        { id: 3, images: tekstyle3 },
        { id: 4, images: tekstyle4 },
      ],
    },
    {
      id: '15',
      text: t('Location.about.inc'),
      images: [
        { id: 1, images: businesink1 },
        { id: 2, images: businesink2 },
        { id: 3, images: businesink3 },
        { id: 4, images: businesink4 },
      ],
    },
    {
      id: '16',
      text: t('Location.about.taiga'),
      images: [
        { id: 1, images: taiga1 },
        { id: 2, images: taiga2 },
        { id: 3, images: taiga3 },
        { id: 4, images: taiga4 },
      ],
    },
    {
      id: '17',
      text: t('Location.about.scor'),
      images: [
        { id: 1, images: skor1 },
        { id: 2, images: skor2 },
        { id: 3, images: skor3 },
        { id: 4, images: skor4 },
      ],
    },
    {
      id: '18',
      text: t('Location.about.les'),
      images: [
        { id: 1, images: donforest1 },
        { id: 2, images: donforest2 },
        { id: 3, images: donforest3 },
        { id: 4, images: donforest4 },
      ],
    },
  ];

  return (
    <div className="container d-grid gap-2 mt-5 mb-5">
      <Link to={'/area'}> {t('Location.back')}</Link>
      <div className="text-wrapper">
        {findAndReplaceBreak(
          areaAbout.find((item) => item.id === id)?.text,
          true,
        )}
      </div>
      <div>
        <div className="d-flex flex-wrap gap-2">
          {areaAbout
            .find((item) => item.id === id)
            ?.images.map(({ id, images }) => (
              <img key={id} className="about-image" src={images} alt="" />
            ))}
        </div>
      </div>
    </div>
  );
};
