import { AboutHome } from './AboutHome';
import { Amo } from './Amo';
import { DownloadForm } from './DownloadForm';
import { MainForm } from './MainForm';
import { MainLines } from './MainLines';
import { MainPartners } from './MainPartners';
import { MainPreference } from './MainPreference';
import { MainProject } from './MainProject';
import { Sliders } from './Sliders';

import './Main.scss';

export const Main = (): JSX.Element => (
  <>
    <Sliders />
    <DownloadForm />
    <AboutHome />
    <MainLines />
    <MainForm />
    <MainProject />
    <MainPartners />
    <MainPreference />
    <Amo />
  </>
);
