import { useEffect, useState } from 'react';

import { ReactComponent as Logo } from '../../../../assets/images/Header/logo_header.svg';
import { ReactComponent as LogoEN } from '../../../../assets/images/Header/logo_header_en.svg';

import '../Header.scss';

interface IProps {
  href: string;
}

export const LogoComponents = ({ href }: IProps): JSX.Element => {
  const [language, setLanguage] = useState(
    window.localStorage.getItem('language'),
  );

  const handlerStorage = () =>
    setLanguage(window.localStorage.getItem('language'));

  useEffect(() => {
    window.addEventListener('storage', handlerStorage);

    return () => {
      window.removeEventListener('storage', handlerStorage);
    };
  }, []);

  const imageMap = {
    ru: <Logo className="logo" />,
    en: <LogoEN className="logo" />,
    zh: <LogoEN className="logo" />,
  };

  return (
    <a href={href}>
      <div className="header-logo">
        {language ? imageMap[language] : imageMap['ru']}
      </div>
    </a>
  );
};
