import { ReactComponent as Check } from '../../../assets/images/About/check.svg';
import { findAndReplaceBreak } from '../../../shared/utils/findAndReplaceBreak';

import './SuccessForm.scss';

interface IProps {
  text: string;
  subtext: string;
}

export const SuccessForm = ({ text, subtext }: IProps): JSX.Element => (
  <div className="success-form">
    <div className="success-inset">
      <div className="success-text">{findAndReplaceBreak(text)}</div>
      <div className="success-check">
        <Check />
      </div>
      <div className="success-text">{findAndReplaceBreak(subtext)}</div>
    </div>
  </div>
);
