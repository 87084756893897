import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import { useEffect, useState } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';

import slide1eng from '../../../assets/images/Main//mainslide1eng.jpg';
import prev from '../../../assets/images/Main/carousel_back.png';
import next from '../../../assets/images/Main/carousel_next.png';
import slide1 from '../../../assets/images/Main/mainslide1.jpg';
import slide2 from '../../../assets/images/Main/mainslide2.jpg';
import slide2eng from '../../../assets/images/Main/mainslide2eng.jpg';

export const Sliders = (): JSX.Element => {
  const [language, setLanguage] = useState(
    window.localStorage.getItem('language'),
  );

  const handlerStorage = () =>
    setLanguage(window.localStorage.getItem('language'));

  useEffect(() => {
    window.addEventListener('storage', handlerStorage);

    return () => {
      window.removeEventListener('storage', handlerStorage);
    };
  }, []);

  const imageMap = {
    ru: { slide1: slide1, slide2: slide2 },
    en: { slide1: slide1eng, slide2: slide2eng },
    zh: { slide1: slide1eng, slide2: slide2eng },
  };

  return (
    <div className="home-slider">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={36}
        totalSlides={2}
        interval={15000}
        infinite={true}
        isPlaying={true}
        dragEnabled={false}
      >
        <Slider>
          <Slide index={1}>
            <img
              className="img-fluid"
              src={
                language
                  ? imageMap[language]['slide2']
                  : imageMap['ru']['slide2']
              }
              alt=""
            />
          </Slide>
          <Slide index={2}>
            <img
              className="img-fluid"
              src={
                language
                  ? imageMap[language]['slide1']
                  : imageMap['ru']['slide1']
              }
              alt=""
            />
          </Slide>
        </Slider>
        <ButtonBack className="slider-button back-slider">
          <img src={prev} alt="" />
        </ButtonBack>
        <ButtonNext className="slider-button next-slider">
          <img src={next} alt="" />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
};
