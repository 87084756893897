import '../Footer.scss';
import { Call } from './Call';
import { FollowMe } from './FollowMe';
import { LogoFooter } from './LogoFooter';
import { Navigation } from './Navigation';

export const FooterComponent = (): JSX.Element => (
  <div className="footer-component">
    <div className="container">
      <div className="d-flex align-items-start justify-content-between">
        <LogoFooter />
        <Navigation />
        <Call />
        <FollowMe />
      </div>
    </div>
  </div>
);
