import { Link } from 'react-router-dom';

import { findAndReplaceBreak } from '../../../shared/utils/findAndReplaceBreak';
interface IProps {
  id: number;
  images: string;
  title: string;
}

export const AreaItems = ({ id, title, images }: IProps): JSX.Element => (
  <Link to={`/area-about?id=${id}`} className="area-item">
    <img src={images} className="area-images" alt="" />
    <div className="area-title mb-0">{findAndReplaceBreak(title)}</div>
  </Link>
);
