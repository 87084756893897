import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { HelloImages } from '../components/HelloImages';
import { Projects } from '../components/Projects/ui/Projects';

export const Project = (): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Title.Project');
  }, [t]);

  return (
    <>
      <Header />
      <HelloImages title={t('HelloImage.Project')} img="service" />
      <Projects />
      <Footer />
    </>
  );
};
