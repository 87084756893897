import { useTranslation } from 'react-i18next';

import star from '../../../assets/images/Main/star.png';
import { findAndReplaceBreak } from '../../../shared/utils/findAndReplaceBreak';

interface ICardItem {
  text: string;
  about: string;
  images: string;
}

export const MainLines = (): JSX.Element => {
  const { t } = useTranslation();

  const numberMap = [
    {
      id: 1,
      text: t('Main.numbers.card1.text'),
      about: t('Main.numbers.card1.about'),
      images: '',
    },
    {
      id: 2,
      text: t('Main.numbers.card2.text'),
      about: t('Main.numbers.card2.about'),
      images: '',
    },
    {
      id: 3,
      text: t('Main.numbers.card3.text'),
      about: t('Main.numbers.card3.about'),
      images: '',
    },
    {
      id: 4,
      text: t('Main.numbers.card4.text'),
      about: t('Main.numbers.card4.about'),
      images: star,
    },
  ];

  const CardItem = ({ text, about, images }: ICardItem): JSX.Element => (
    <div className="number-block">
      <div className="items-number">
        <div className="d-flex gap-1 number-wrapper">
          <p className="mb-0 numbers">{text}</p>
          {images !== '' ? (
            <p className="mb-0 plus">
              <img src={images} alt="" />
            </p>
          ) : (
            <p className="mb-0 plus">+</p>
          )}
        </div>
        <p className="mb-0 item-number-text">{findAndReplaceBreak(about)}</p>
      </div>
    </div>
  );

  return (
    <div className="number-block-wrapper">
      <div className="container number-block-inset">
        {numberMap.map(({ id, text, about, images }) => (
          <CardItem key={id} text={text} about={about} images={images} />
        ))}
      </div>
    </div>
  );
};
