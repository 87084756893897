import { Helmet } from 'react-helmet';

export const Amo = (): JSX.Element => (
  <div id="amo1">
    <div id="amo2">
      <Helmet />
      <iframe
        id="amoforms_iframe_439900"
        className="amoforms_iframe"
        name="amoforms_iframe_439900"
        scrolling="no"
        frameBorder="0"
        style={{ width: '100%', height: '42rem' }}
        src='https://forms.amocrm.ru/forms/html/form_439900_eefea9cc34001ed96240ab9a2901b9e9.html?date=1710665351#{"form_id":"439900","form_hash":"eefea9cc34001ed96240ab9a2901b9e9","user_origin":{"datetime":"Sun Mar 17 2024 11:49:11 GMT+0300 (GMT+03:00)","referer":""},"is_modal":false,"success_message":"%D0%91%D0%BB%D0%B0%D0%B3%D0%BE%D0%B4%D0%B0%D1%80%D0%B8%D0%BC%20%D0%92%D0%B0%D1%81%20%D0%B7%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D1%89%D0%B5%D0%BD%D0%B8%D0%B5!","has_redirect":"","is_dark_bg":false,"utm":{"source":"","medium":"","content":"","campaign":"","term":""},"ga":{},"location":"https://event-360.ru/","dp":{}}'
      />
    </div>
  </div>
);
