import { useState } from 'react';

import { ReactComponent as Burger } from '../../../../assets/images/Header/burger.svg';
import { MobileMenu } from '../../../MobileMenu';

import '../Header.scss';

export const BurgerComponent = (): JSX.Element => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        className="header-burger header-hovered"
        onClick={() => setShow(!show)}
      >
        <Burger />
      </div>
      <MobileMenu show={show} setShow={setShow} />
    </>
  );
};
