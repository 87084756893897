import './HelloImages.scss';
import cn from 'classname';

import area from '../../../assets/images/Background/area.png';
import service from '../../../assets/images/Background/service.jpg';

interface IProps {
  title: string;
  img: string;
}

export const HelloImages = ({ title, img }: IProps): JSX.Element => {
  const imageMap = {
    service: service,
    area: area,
  };

  return (
    <div
      className={cn('hello-images')}
      style={{ background: `url(${imageMap[img]})` }}
    >
      <p className="mb-0 hello-images-title">{title}</p>
      <div className=" hello-images-hr" />
    </div>
  );
};
